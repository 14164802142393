<template>
  <app-view-container>
    <v-card-title class="text-h3 semi-bold px-6 pt-2 d-block">
      <partner-logos height="60px" width="60px" class="mt-2 mb-6" />
      <h2 class="mt-4">
        {{ $t('reload.currentUserChanged') }}
      </h2>
    </v-card-title>

    <v-card-text class="body-1 px-6">
      <p class="mt-4" v-html="$t('reload.info')"></p>
    </v-card-text>

    <v-spacer />

    <v-divider />

    <v-card-actions class="px-6 py-5">
      <app-btn type="submit" block @click="reloadPage">{{ $t('reload.reloadPage') }}</app-btn>
    </v-card-actions>
  </app-view-container>
</template>

<script>
import PartnerLogos from '@/shared/components/PartnerLogos';

export default {
  name: 'ReloadPage',

  components: {
    PartnerLogos
  },

  methods: {
    reloadPage() {
      if (Object.keys(this.$route.params).length > 0 || Object.keys(this.$route.query).length > 0) {
        this.$router
          .push({ name: this.$route.name, params: undefined, query: undefined })
          .catch(() => {});
      }

      window.location.reload();
    }
  }
};
</script>
